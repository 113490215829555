import * as React from 'react';
import { graphql } from 'gatsby';
import { BuilderComponent } from '@builder.io/react';
import { Helmet } from 'react-helmet';
import Link from '../components/Link/Link';

const defaultDescription = 'Edit this in your entry for a better SEO';
const defaultTitle = 'Olokoo Q2M';

function PageTemplate({ data }) {
  const models = data?.allBuilderModels;
  const page = models.page[0]?.content;
  const sitePreferences = models.sitePreferences[0];

  return (
    <>
      <Helmet>
        <title>{(page && page.data.title) + (sitePreferences && sitePreferences.data.siteName ? ' - ' + sitePreferences.data.siteName : '') || defaultTitle}</title>
        <meta
          name="description"
          content={
            (page && page.data.description) || defaultDescription
          }
        />
      </Helmet>
      <BuilderComponent
        renderLink={Link}
        name="page"
        content={page}
      />
    </>
  );
}

export default PageTemplate;

export const pageQuery = graphql`
  query($path: String!) {
    allBuilderModels {
      page(
        target: { urlPath: $path }
        limit: 1
        options: { cachebust: true }
      ) {
        content
      },
      sitePreferences {
        data {
          siteName
        }
      }
    }
  }
`;
